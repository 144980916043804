.MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
}

.MuiButton-root {
    border-radius: 8px !important;
    text-transform: none !important;
}

.MuiButton-root {
    border-radius: 8px !important;
    text-transform: none !important;
}

.MuiPaper-rounded {
    border-radius: 16px !important;
}

.MuiOutlinedInput-root {
    border-radius: 8px !important;
}
.MuiMenuItem-root {
    border-radius: 8px !important;
}

body {
    font-size: 16px !important;
}
