.ev-untagged-chip {
    font-size: 12px !important;
    color: #f44336 !important;
    border: 1px solid #f44336 !important;
    border-radius: 8px;
    background-color: rgba(244, 67, 54, 0.16) !important;
    padding: 4px !important;
    text-align: center !important;
}

.ev-tagged-chip {
    font-size: 12px !important;
    color: #4caf50 !important;
    border: 1px solid #4caf50 !important;
    border-radius: 8px;
    background-color: rgba(76, 175, 80, 0.16) !important;
    padding: 4px !important;
    text-align: center !important;
}
.ev-available-chip {
    font-size: 12px !important;
    color: #43419e !important;
    border: 1px solid #43419e !important;
    border-radius: 8px;
    background-color: #eeedfc !important;
    padding: 4px !important;
    text-align: center !important;
}

.ev-released-chip {
    font-size: 12px !important;
    color: #ff9800 !important;
    border: 1px solid #ff9800 !important;
    border-radius: 8px;
    background-color: rgba(255, 152, 0, 0.16) !important;
    padding: 4px !important;
    text-align: center !important;
}
