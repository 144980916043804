.hire-item {
    .MuiChip-label {
        font-size: 12px !important;
        width: 100px !important;
    }

    .MuiTableCell-head {
        color: #525050 !important;
        font-weight: bold !important;
    }

    .open-chip {
        font-size: 12px !important;
        width: 50%;
        color: #ff9800 !important;
        border: 1px solid #ff9800 !important;
        border-radius: 8px;
        background-color: rgba(255, 152, 0, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .completed-chip {
        font-size: 12px !important;
        width: 50%;
        color: #b33fb5 !important;
        border: 1px solid #b33fb5 !important;
        border-radius: 8px;
        background-color: rgba(179, 63, 181, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }

    .markedincomplete-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #2a8dd4 !important;
        border: 1px solid #2a8dd4 !important;
        border-radius: 8px;
        background-color: rgba(42, 141, 212, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .rejected-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #f44336 !important;
        border: 1px solid #f44336 !important;
        border-radius: 8px;
        background-color: rgba(244, 67, 54, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }

    .verified-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #4caf50 !important;
        border: 1px solid #4caf50 !important;
        border-radius: 8px;
        background-color: rgba(76, 175, 80, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }

    .incomplete-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #2c28df !important;
        border: 1px solid #2c28df !important;
        border-radius: 8px;
        background-color: rgba(44, 40, 223, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .converted-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #949393 !important;
        border: 1px solid #949393 !important;
        border-radius: 8px;
        background-color: rgba(82, 80, 80, 0.04) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .onhold-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #949393 !important;
        border: 1px solid #F4CD00 !important;
        border-radius: 8px;
        background-color: rgba(255, 230, 100, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .lead-type-chip {
        font-size: 10px !important;
        width: 'auto';
        color: #949393 !important;
        border: 1px solid #949393 !important;
        border-radius: 6px;
        background-color: rgba(82, 80, 80, 0.04) !important;
        padding: 2px 4px !important;
        text-align: center !important;
        font-weight: 600;
    }
    .lead-type-chip-referral {
        color: #43419e !important;
        background-color: #ebebff !important;
        border: 1px solid #43419e !important;
    }

    a:hover {
        color: #43419e !important;
        background-color: rgba(67, 65, 158, 0.1) !important;
    }
}

.basic-info {
    .image-upload-wrap img,
    .image-upload-wrap,
    .file-upload {
        height: 260px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image-upload-wrap img {
        border-radius: 0 !important;
    }
}
