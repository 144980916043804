.payslip-list {
    .MuiChip-label {
        padding: 0 !important;
        font-size: 12px !important;
        width: 100px !important;
    }

    .MuiTableCell-head {
        color: #525050 !important;
        font-weight: bold !important;
    }

    .chip {
        font-size: 12px !important;
        width: 60%;
        color: #525050 !important;
        border: 1px solid #5351512d !important;
        border-radius: 8px;
        background-color: rgba(163, 52, 52, 0.04) !important;
        padding: 4px !important;
        text-align: center !important;
    }
    .pending_signature {
        color: #ff9800 !important;
        border: 1px solid #ff9800 !important;
        background-color: rgba(255, 152, 0, 0.16) !important;
    }

    .kyc-rej-chip {
        color: #f44336 !important;
        border: 1px solid #f44336 !important;
        background-color: rgba(244, 67, 54, 0.16) !important;
    }

    .ready_for_payment {
        color: #4caf50 !important;
        border: 1px solid #4caf50 !important;
        background-color: rgba(76, 175, 80, 0.16) !important;
    }

    .pending_approval {
        color: #3f51b5 !important;
        border: 1px solid #3f51b5 !important;
        background-color: rgba(63, 81, 181, 0.16) !important;
    }

    a:hover {
        color: #43419e !important;
        background-color: rgba(67, 65, 158, 0.1) !important;
    }
}
