.payment-summary {
    color: #000;
    
}

// .MuiAccordion-root {
//     color: #000;
//     .MuiAccordion-rounded {
//         border-radius: 5px !important;
//     }
// }
// .MuiTreeView-root {
//     .MuiTreeItem-root:first-child {
//         .MuiTreeItem-content {
//             border-top-left-radius: 5px;
//             border-top-right-radius: 5px;
//         }
//     }
//     .MuiTreeItem-root:last-child {
//         .MuiTreeItem-content {
//             border-bottom-left-radius: 5px;
//             border-bottom-right-radius: 5px;
//         }
//     }
//     li.MuiTreeItem-root {
//         border-radius: 5px !important;
//         // background-color: #ebedf8;
//         .MuiTreeItem-content {
//             height: 3.5rem;
//             padding: 1rem 0.938rem 1rem 0.5rem;
//             box-shadow: inset 0 -1px 0 0 #ebedf8;
//             background-color: #f8f9fa;
//             justify-content: center;
//             align-items: center;
//             box-shadow: inset 0 -1px 0 0 #ebedf8;
//             background-color: #ffffff;
//         }
//         &:nth-child(odd) {
//             .MuiTreeItem-content {
//                 box-shadow: inset 0 -1px 0 0 #ebedf8;
//                 background-color: #f8f9fa;
//             }
//         }
//         .Mui-expanded {
//             background-color: #ebedf8;
//             .MuiCollapse-entered {
//                 margin-left: 20;
//                 padding-left: 20;
//             }
//             .MuiTreeItem-content {
//                 background-color: #ebedf8 !important;
//             }
//             .MuiCollapse-entered {
//                 padding-left: 0;
//             }
//         }
//     }
// }
// .MuiTreeItem-iconContainer {
//     margin-right: 1rem;
// }

// .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
//     background-color: transparent !important;
// }

// .MuiGrid-item {
//     border-right: 1px #ebedf8 solid;
//     border-left: 1px #ebedf8 solid;
// }
// .MuiGrid-item:nth-child(1) {
//     border-right: 1px #ebedf8 solid;
//     border-left: none;
// }
// .MuiGrid-item:last-child {
//     border-right: none;
//     border-left: 1px #ebedf8 solid;
// }

// .MuiTreeItem-group {
//     padding-left: 16px;
// }