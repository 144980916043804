.header {
    background-color: #fafafa;
    padding: 16px 30px;
    font-size: 20px;
    color: #000;
    word-spacing: 3px;
    letter-spacing: 1px;
}
.rate-card-container {
    background-color: #f1f3f6;
    width: 100%;
    min-height: 70vh;
}
.rate-card-container .card {
    background-color: #fff;
    padding: 8px;
    border-radius: 8px;
    color: #0a0808;
    margin-bottom: 10px;
}
.rate-card-container .card .card-conditions {
    background-color: #eeeef9;
    margin-top: 8px;
    padding: 8px 16px;
}
.rate-card-container .card .card-conditions p {
    margin: 0;
    font-size: 10px;
}
.rate-card-container .card .card-conditions p:first-child {
    font-size: 12px;
    font-weight: bold;
}
.rate-card-container .card .list-view-card {
    background-color: #eeeef9;
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 16px;
}
.rate-card-container .card .list-view-card .title {
    font-size: 12px;
    font-weight: 600;
}
.rate-card-container .card .list-view-card .description {
    font-size: 10px;
}
.rate-card-container .card .combo-list-view-card {
    background-color: #eeeef9;
    margin-top: 8px;
    padding: 8px;
    border: 1px solid #dedede;
}
.rate-card-container .card .combo-list-view-card .title {
    font-size: 14px;
    font-weight: 600;
}
.rate-card-container .card .combo-list-view-card .description {
    font-size: 10px;
}
.rate-card-container .card .card-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.rate-card-container .card img.icon {
    width: 24px;
    height: 24px;
}
.rate-card-container .card .title {
    font-size: 14px;
    font-weight: bold;
}
.rate-card-container .card .description {
    font-size: 10px;
}
.rate-card-container .timeline {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    padding: 0;
}
.rate-card-container .li {
    transition: all 200ms ease-in;
    width: 70px;
}
.rate-card-container .li:first-child {
    width: 100%;
}
.rate-card-container .li:first-child .status h4,
.rate-card-container .li:first-child .timestamp h4 {
    width: 100px;
}
.rate-card-container .timestamp {
    margin-bottom: 5px;
    padding: 0px 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 100;
    font-size: 12px;
}
.rate-card-container .status {
    padding: 0px 3px;
    display: flex;
    justify-content: flex-start;
    border-top: 2px solid #2b2b2b;
    position: relative;
    transition: all 200ms ease-in;
}
.rate-card-container .timestamp h4,
.rate-card-container .status h4 {
    font-size: 11px;
    font-weight: normal;
    margin-top: 10px;
}
.rate-card-container .status:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 25px;
    position: absolute;
    top: -7px;
    transition: all 200ms ease-in;
}
.rate-card-container .li.complete .timestamp {
    align-items: center;
}
.rate-card-container .li.complete .status {
    border-top: 2px solid #2b2b2b;
    justify-content: center;
}
.rate-card-container .li.complete .status:before {
    background-color: #fd6a00;
    border: none;
    transition: all 200ms ease-in;
}
