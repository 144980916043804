@import '../../utils/colors.scss';

.horizontalCardContents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cardStyles {
    border-radius: 16px;
    padding: 24px;
}

.btn-pos {
    margin-right: 8px !important;
}
.btn-pos-end {
    margin-right: 10px !important;
}

.horiz-end {
    display: flex;
    justify-content: space-between;
}

.bottom-mar {
    margin-bottom: 8px;
}

// .grid-item {
//     border: 1px solid black;
//     margin-right: 1rem;
// }

// .list-item-container {
//     display: flex;
//     flex-direction: row;
//     margin-bottom: '1rem';
//     // border: '1px solid black',
// }

// .col-25p {
//     flex: 0 1 25%;
//     margin-right: 5px;
//     margin-left: 1.5rem;
// }

// .col-15p {
//     flex: 0 1 15%;
//     margin-right: 5px;
// }

// .col-10p {
//     flex: 0 1 10%;
// }
