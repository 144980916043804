.appbar {
    .MuiAppBar-root {
        z-index: 999;
    }
    .MuiTabs-flexContainer {
        justify-content: center;
        .MuiTab-wrapper {
            font-size: 1rem;
            font-weight: 600;
        }
        .Mui-selected {
            .MuiTab-wrapper {
                font-weight: 700;
            }
        }
    }
    .MuiTab-root {
        text-transform: none;
    }
}
