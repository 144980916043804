.jspin-nested-loading {
    position: relative;
}

.jspin {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: absolute;
    display: none;
    color: #216462;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    // -webkit-transition: -webkit-transform .3s cubic-bezier(.78, .14, .15, .86);
    // transition: -webkit-transform .3s cubic-bezier(.78, .14, .15, .86);
    // transition: transform .3s cubic-bezier(.78, .14, .15, .86);
    // transition: transform .3s cubic-bezier(.78, .14, .15, .86), -webkit-transform .3s cubic-bezier(.78, .14, .15, .86);
}

.jspin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
}

.jspin-nested-loading > .jspin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
}

.jspin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}

.jspin-container {
    position: relative;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
}

.jspin-blur::after {
    opacity: 0.8;
    pointer-events: auto;
}

.jspin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: '';
    pointer-events: none;
}

.jspin-nested-loading > .jspin .jspin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
}
