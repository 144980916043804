$common_black: rgba(0, 0, 0, 1);
$common_white: rgba(255, 255, 255, 1);

$background_paper: rgba(255, 255, 255, 1);
$background_default: rgba(241, 243, 246, 1);

$primary_light: #7986cb;
$primary_main: #43419e;
$primary_dark: #303f9f;
$primary_default: #fff;
$primary_contrastText: rgba(255, 255, 255, 1);

$error_light: #e57373;
$error_main: #f44336;
$error_dark: #d32f2f;
$error_contrastText: #fff;

$text_primary: #43419e;
$text_secondary: #515f6d;
$text_dark: #14112d;
$text_disabled: rgba(0, 0, 0, 0.38);
$text_hint: rgba(0, 0, 0, 0.38);
