.recharts-legend-wrapper {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
}
.legend-item-0 {
    margin-right: 30px !important;
}
.legend-item-1 {
    margin-right: 30px !important;
}

.recharts-legend-item-text {
    font-size: 18px;
    font-weight: 600;
    margin-left: 4px;
}
