@import '../../utils/colors.scss';

.tabbar {
    .tab {
        color: #65656d;
        padding: 16px 4px;
        // min-width: 150px;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        flex-direction: row;
        background-color: #f6f7f9;
        cursor: pointer;
        border: 1px #ebedf8 solid;
        margin: 0 5px;
        svg {
            g {
                fill: #65656d;
            }
            path {
                fill: #65656d;
            }
        }
        .MuiTypography-body1 {
            color: #65656d;
        }
    }
    .disabled {
        color: #65656d80;
        background-color: #f6f7f9;
        cursor: not-allowed;
        border: 1px #ebedf8 solid;
        svg {
            g {
                fill: #65656d80;
            }
            path {
                fill: #65656d80;
            }
        }
        .MuiTypography-body1 {
            color: #65656d80;
        }
    }
    .active {
        background-color: $primary_main;
        .MuiTypography-body1 {
            color: $common_white;
        }
        svg {
            g {
                fill: #fff;
            }
            path {
                fill: #fff;
            }
        }
    }
}
