.staff-list {
    .MuiChip-label {
        padding: 0 !important;
        font-size: 12px !important;
        width: 100px !important;
    }

    .MuiTableCell-head {
        color: #525050 !important;
        font-weight: bold !important;
    }

    .kyc-pen-chip {
        font-size: 12px !important;
        width: 50%;
        color: #ff9800 !important;
        border: 1px solid #ff9800 !important;
        border-radius: 8px;
        background-color: rgba(255, 152, 0, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }
    .ev-pen-chip {
        font-size: 12px !important;
        font-weight: bold;
        color: #ff9800 !important;
        border-radius: 8px;
        background-color: rgba(255, 152, 0, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }

    .kyc-rej-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #f44336 !important;
        border: 1px solid #f44336 !important;
        border-radius: 8px;
        background-color: rgba(244, 67, 54, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }
    .ev-rej-chip {
        font-size: 12px !important;
        font-weight: bold;
        color: #f44336 !important;
        border-radius: 8px;
        background-color: rgba(244, 67, 54, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }

    .active-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #4caf50 !important;
        border: 1px solid #4caf50 !important;
        border-radius: 8px;
        background-color: rgba(76, 175, 80, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }
    .ev-active-chip {
        font-size: 12px !important;
        color: #4caf50 !important;
        border-radius: 8px;
        font-weight: bold;
        background-color: rgba(76, 175, 80, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }
    .request-rent {
        font-size: 12px !important;
        color: #43419e !important;
        font-weight: bold;
        border-radius: 8px;
        background-color: #e4e3f4 !important;
        padding: 4px !important;
        text-align: center !important;
    }

    .inactive-chip {
        font-size: 12px !important;
        width: 47.5%;
        color: #3f51b5 !important;
        border: 1px solid #3f51b5 !important;
        border-radius: 8px;
        background-color: rgba(63, 81, 181, 0.16) !important;
        padding: 4px !important;
        text-align: center !important;
    }

    a:hover {
        color: #43419e !important;
        background-color: rgba(67, 65, 158, 0.1) !important;
    }
}
