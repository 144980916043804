.leaflet-marker-icon-wrapper {
    margin-left: -12px;
    margin-top: -35px;
}
.map-label-with-text-shadow {
    position: absolute;
    color: white;
    text-shadow: 2px 0 5px #000, 2px 0 5px #000, 0 2px 5px #000, 0 -2px 5px #000,
        1px 1px 5px #000, -1px -1px 5px #000, 1px -1px 5px #000,
        -1px 1px 5px #000;
}
.leaflet-playback-marker-icon-wrapper {
    margin-left: -12px;
    margin-top: -20px;
}
.custom-button {
    background: white;
    border-radius: 6px;
    /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
    padding: 7px;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.custom-button button {
    background: white;
    border: none;
    color: black;
    cursor: pointer;
    padding: 0;
    font-size: 24px;
}

.custom-button button:hover {
    background: white;
}
